import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'app/api.service';
@Component({
  selector: 'app-nt-services',
  templateUrl: './nt-services.component.html',
  styleUrls: ['./nt-services.component.scss']
})
export class NtServicesComponent implements OnInit {
  activeProductID;
  activeProductIndex:number;
  travelServices:string[]=[];
  submittingService:boolean=false;
  add_travelService: boolean=false;
  update_travelService:boolean=false;
  waitingFetch: boolean=true;
  serviceForm: FormGroup= this._fb.group({
    service_name: [null, [Validators.required]],
    pricing: this._fb.group({
      base_price: [null, [Validators.required]],
      sale_price: [null, [Validators.required]]
    }),
    desc: [null, [Validators.required]],
    req: [null, [Validators.required]],
    img_src: ['https://static.thenounproject.com/png/396915-200.png', [Validators.required]],
    img_type: [null],
    status: [null, [Validators.required]]
  });
  constructor(
    private _fb: FormBuilder,
    private _http: HttpClient,
    public api: ApiService,
    private cd: ChangeDetectorRef,

  ) { }

  ngOnInit() {
    this.fetchServices();
  }

  fetchServices(){
    this.waitingFetch = true;
    this._http.get(`${this.api.base_url}/api/products/tservices-crud/`).subscribe(
      res => {
        this.travelServices= res['data']
        this.waitingFetch = false;
      },
      err => {
      }
    )
  }
  submitNewService(){
    this.submittingService=true;
    if (this.serviceForm.valid && !this.update_travelService){
      this._http.post(`${this.api.base_url}/api/products/tservices-crud/`, this.serviceForm.value).subscribe(
        res =>{
          this.submittingService=false;
          this.serviceForm.reset();
          this.serviceForm.patchValue({
            img_src: 'https://static.thenounproject.com/png/396915-200.png'
          });
          this.add_travelService=false;
          this.update_travelService=false;
          this.travelServices.push(res['data'])
        },
        err =>{
          this.submittingService=false;

        }
      )
    }
    if (this.serviceForm.valid && this.update_travelService){
      this._http.patch(`${this.api.base_url}/api/products/tservices-crud/?p_id=${this.activeProductID}`, this.serviceForm.value).subscribe(
        res =>{
          this.submittingService=false;
          this.serviceForm.reset();
          this.serviceForm.patchValue({
            img_src: 'https://static.thenounproject.com/png/396915-200.png'
          });
          this.travelServices[this.activeProductIndex] = res['data']
          this.add_travelService=false;
          this.update_travelService=false;
          this.activeProductID=null;
          this.activeProductIndex=null;
        },
        err =>{
          this.submittingService=false;

        }
      )
    }
  }

  editService(service, index){
    let product = service;
    this.serviceForm.patchValue({
      service_name: service['details']['title'],
      desc: service['details']['description'],
      req: service['details']['requirements'],
      img_src: service['details']['image'],
      status: service['status']['code']
    })
    this.serviceForm.controls['pricing'].patchValue({
      base_price: service['details']['base_price'],
      sale_price: service['details']['sale_price']
    })
    this.add_travelService=true;
    this.update_travelService=true;
    this.activeProductID=service['unique_id'];
    this.activeProductIndex=index;
  }

  cancelOperation(){
    this.add_travelService=false;
    this.update_travelService=false;
    this.serviceForm.reset();
    this.serviceForm.patchValue({
      img_src: 'https://static.thenounproject.com/png/396915-200.png'
    })
  }
  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
     let reader = new FileReader(); // HTML5 FileReader API
     let file = event[index];
     if (event[index]) {
       reader.readAsDataURL(file);
       // When file uploads set it to file formcontrol
       reader.onload = () => {
        this.serviceForm.patchValue({
          img_src:reader.result,
          img_type: file.type
        })
       }
       // ChangeDetectorRef since file is loading outside the zone
       this.cd.markForCheck();        
     }
    } 
  }

}
