import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'app/api.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  apiKey = "qwyj24sqkw361y1o7n0dg6anzn4qk0pd0i47x0oyllpgqo41";
  activePolicyID;
  activePolicyIndex:number;
  policies:string[]=[];
  submittingPolicy:boolean=false;
  add_policy: boolean=false;
  update_policy:boolean=false;
  waitingFetch: boolean=true;
  policyForm: FormGroup= this._fb.group({
    title: [null, [Validators.required]],
    number: [null, [Validators.required]],
    version_no: [null, [Validators.required]],
    body: [null, [Validators.required]],
  });
  constructor(
    private _fb: FormBuilder,
    private _http: HttpClient,
    public api: ApiService,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    this.fetchPolicies();
  }

  fetchPolicies(){
    this.waitingFetch = true;
    this._http.get(`${this.api.base_url}/api/policy/`).subscribe(
      res => {
        this.policies= res['data']
        this.waitingFetch = false;
      },
      err => {
      }
    )
  }
  submitNewPolicy(){
    this.submittingPolicy=true;
    if (this.policyForm.valid && !this.update_policy){
      this._http.post(`${this.api.base_url}/api/policy/`, this.policyForm.value).subscribe(
        res =>{
          this.submittingPolicy=false;
          this.policyForm.reset();
          this.policyForm.patchValue({
            img_src: 'https://static.thenounproject.com/png/396915-200.png'
          });
          this.add_policy=false;
          this.update_policy=false;
          this.policies.push(res['data'])
        },
        err =>{
          this.submittingPolicy=false;

        }
      )
    }
    if (this.policyForm.valid && this.update_policy){
      this._http.patch(`${this.api.base_url}/api/policy/${this.activePolicyID}/`, this.policyForm.value).subscribe(
        res =>{
          this.submittingPolicy=false;
          this.policyForm.reset();
          this.policies[this.activePolicyIndex] = res['data']
          this.add_policy=false;
          this.update_policy=false;
          this.activePolicyID=null;
          this.activePolicyIndex=null;
        },
        err =>{
          this.submittingPolicy=false;

        }
      )
    }
  }

  editPolicy(policy, index){
    this.policyForm.patchValue({
      title: policy['title'],
      number: policy['number'],
      version_no: policy['version_no'],
      body: policy['body'],
    })
    this.add_policy=true;
    this.update_policy=true;
    this.activePolicyID=policy['uid'];
    this.activePolicyIndex=index;
  }

  cancelOperation(){
    this.add_policy=false;
    this.update_policy=false;
    this.policyForm.reset();
  }

  readPolicy(policy): void {
    const dialogRef = this.dialog.open(PolicyDialog, {
      height: '500px',
      width: 'auto',
      data: policy
    });

    // dialogRef.afterClosed().subscribe(result => {
    // });
  }


}


@Component({
  selector: 'policy-dialog',
  templateUrl: 'policy-dialog.html',
})
export class PolicyDialog {

  constructor(
    public dialogRef: MatDialogRef<PolicyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Policy) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
export interface Policy {
  title: string;
  number: string;
  version_no: string;
  body: string;
  last_updated: Date
}