import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'app/api.service';
import { RoadtripService } from './roadtrip-service.service';

@Component({
  selector: 'app-roadtrip',
  templateUrl: './roadtrip.component.html',
  styleUrls: ['./roadtrip.component.css']
})
export class RoadtripComponent implements OnInit {

  constructor(
    private rt_service: RoadtripService
  ) { }

  ngOnInit(): void {
    this.rt_service.get_openDestinations();
    this.rt_service.get_fleetData();    
  }

}
