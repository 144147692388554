import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersListComponent, OrderDeleteDialogue } from './orders-list/orders-list.component';
import { RouterModule } from '@angular/router';
import { OrderRoutes } from './orders.routing';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Imgdialog, OrderViewComponent } from './order-view/order-view.component';
import { OrderAddComponent } from './order-add/order-add.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
@NgModule({
  declarations: [OrdersListComponent, OrderDeleteDialogue,Imgdialog, OrderViewComponent, OrderAddComponent, OrderEditComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(OrderRoutes),
    MatTooltipModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatCheckboxModule,
    NgbPaginationModule,
    MatProgressSpinnerModule,
    MatDialogModule
  ],
  entryComponents:[
    Imgdialog,
    OrderDeleteDialogue
  ],
})
export class OrdersModule { }
