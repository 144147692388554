import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'app/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, of, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { delay, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  
  public keyUp = new Subject<KeyboardEvent>();
  private search_subscription: Subscription;

  agencies: any;
  constructor(
    public http: HttpClient,
    public api: ApiService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.search_subscription = this.keyUp.pipe(
      map(event => event.target['value']),
      debounceTime(700),
      distinctUntilChanged(),
      mergeMap(search => of(search).pipe(
        delay(500),
      )),
    ).subscribe(search_text => {
      this.searchAgency(search_text)
    });

  }
  searchQuery:string=null;
  page:number = 1;
  httpWait_fetchingData:boolean=false;
  spinner_mode: ProgressSpinnerMode = 'indeterminate';
  error_404: boolean=false;
  ngOnInit() {
    this._route.queryParamMap.subscribe(urlQuery => {
      let query: string = '';
      if(urlQuery['params']['page'] || urlQuery['params']['search']){
        if(urlQuery['params']['search']){
          query = urlQuery['params']['search'];
        }
        this.fetchAgencyList(urlQuery['params']['page'], query)
      } else{
        this.fetchAgencyList(1, '')
      }
    });
  }

  fetchAgencyList(page, query){
    this.httpWait_fetchingData = true;
    return this.http.get(`${this.api.base_url}/api/agency/agencies_list/?page=${page}&search=${query}`).subscribe(
      res => {
        this.agencies = res,
        this.page=page,
        this.searchQuery=query,
        this.httpWait_fetchingData = false;
      },
      err=> {
        this.httpWait_fetchingData = false;
      });
  }

  searchAgency(searchText){
    this.searchQuery = searchText;
    this.navigatePage(1);
  }

  navigatePage(p){
    if(this.searchQuery){
      this._router.navigate(
        ['/agency'], {queryParams: { 'page': p, 'search': this.searchQuery} }
      );
    }
    else {
      this._router.navigate(
        ['/agency'], {queryParams: { 'page': p} }
      );
    }
  }

  ngOnDestroy(): void {
    this.search_subscription.unsubscribe();
  }

}
