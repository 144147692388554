import { Component, OnInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  loginPage:boolean= false;
  constructor(
    private router: Router
  ){}

  ngOnInit(){
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(e =>{
      if(e['url'] == '/login'){
        this.loginPage = true
      }
      else{this.loginPage = false}
    });
  }
}
