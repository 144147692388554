import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'app/api.service';

@Component({
  selector: 'app-airline',
  templateUrl: './airline.component.html',
  styleUrls: ['./airline.component.scss']
})
export class AirlineComponent implements OnInit {
  airTickets:string[];
  submittingRoute:boolean=false;
  addRoute: boolean=true;
  ticketForm: FormGroup;
  constructor(
    private _fb: FormBuilder,
    private _http: HttpClient,
    public api: ApiService
  ) { }

  ngOnInit() {
    this.ticketForm = this._fb.group({
      departure: this._fb.group({
        departCountry: [null, [Validators.required]],
        departCity: [null, [Validators.required]],
        departAirport: [null, [Validators.required]],
      }),
      departDate: [null, [Validators.required]],

      arrival: this._fb.group({
        arrivalCountry: [null, [Validators.required]],
        arrivalCity: [null, [Validators.required]],
        arrivalAirport: [null, [Validators.required]]
      }),

      flightDuration: [null, [Validators.required]],
      pricing: this._fb.group({
        adult: [null, [Validators.required]],
        child: [null, [Validators.required]],
        infant: [null, [Validators.required]]
      }),
      ticketInfo: this._fb.group({
        ticketType: [null, [Validators.required]],
        airliner: [null, [Validators.required]],
        ticketCode: [null, [Validators.required]],

      }),
      flightStops: this._fb.array([
        this._fb.group({
          stopDate: [null, [Validators.required]],
          stopAirport: [null, [Validators.required]],
          stopCity: [null, [Validators.required]],
          stopCountry: [null, [Validators.required]],
        })
      ])
    })
  }

  submitNewTicket(){
    if (this.ticketForm.valid){
      this._http.post(`${this.api.base_url}/api/products/airtickets-crud/`, this.ticketForm.value).subscribe(
        res =>{
          
        },
        err =>{

        }
      )
    }
  }

}
