import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AgencyComponent } from './agency/agency.component';
import { VisaComponent } from './visa/visa.component';
import { AccountingComponent } from './accounting/accounting.component';
import { ToursComponent } from './tours/tours.component';
import { ToursModule } from './tours/tours.module';
import { OrdersComponent } from './orders/orders.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AirlineComponent } from './airline/airline.component';
import { NtServicesComponent } from './nt-services/nt-services.component';
import { RoadtripComponent } from './roadtrip/roadtrip.component';
import { PolicyComponent } from './policy/policy.component';
const routes: Routes =[
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',

  },
  // {
  //   path: 'airline',
  //   component: AirlineComponent,
  //   canActivate: [AuthGuard]

  // },
  {
    path: 'login',
    component: LoginComponent
  },
  { 
    path: 'agency',
    component: AgencyComponent,
    loadChildren: () => import('./agency/agency.module').then(m => m.AgencyModule),
    canActivate: [AuthGuard]

  },
  { 
    path: 'visa',
    component: VisaComponent,
    loadChildren: () => import('./visa/visa.module').then(m => m.VisaModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'accounting',
    component: AccountingComponent,
    loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'tours',
    component: ToursComponent,
    loadChildren: () => import('./tours/tours.module').then(m => m.ToursModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'orders',
    component: OrdersComponent,
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'road-trips',
    loadChildren: () => import('./roadtrip/roadtrip.module').then(m => m.RoadtripModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'policy',
    component: PolicyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'nt-services',
    component: NtServicesComponent,
    canActivate: [AuthGuard]

  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
    useHash: false,
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
