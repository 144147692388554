import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.scss']
})
export class ToursComponent implements OnInit {

    constructor() {}

    ngOnInit() {
    }
    ngAfterViewInit() {
    }

}
