import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';  // updated import


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

    constructor() {}

    ngOnInit() {
    }
    ngAfterViewInit() {
    }

}
