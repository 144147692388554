import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  err=false;
  loginsuccess;
  returnUrl: string;
  focus;
  focus1;
  refreshToken = localStorage.getItem('RefreshToken');
  accessToken = localStorage.getItem('AccessToken');
  constructor(
      private fb: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: LoginService

  ) { }
  ngOnInit() {
    if (this.refreshToken && this.accessToken ) {
    }
        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

}
get f() { return this.loginForm.controls; }
not_staff:boolean;
onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }
    this.loading = true;
    this.err=false
    this.authenticationService.login(this.f['username'].value, this.f['password'].value)
        .subscribe(
            data => {
                if(data !=false){
                    this.loading=false;
                    this.loginsuccess = true;
                    window.location.replace(this.returnUrl);
                    this.not_staff = false;
                }
                else{
                    this.loading=false;
                    this.not_staff=true
                }
            },
            error => {
                console.log(error)
                this.loading = false;
                this.err=true
            });
}

}
