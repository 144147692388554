import { Routes } from '@angular/router';
import { AccountsComponent } from './accounts/accounts.component';
import { AgencyAccountComponent } from './agency-account/agency-account.component';



export const AccountingRoutes: Routes = [
    {
        path: '',
        component: AccountsComponent,
    },
    {
        path: 'agency-account',
        component: AgencyAccountComponent,
    },
];
