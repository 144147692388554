import { Routes } from '@angular/router';

import { ListComponent } from './list/list.component';
import { AddTourComponent } from './add-tour/add-tour.component';
import { EditTourComponent } from './edit-tour/edit-tour.component';

export const TourRoutes: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
    },
    { 
        path: 'list',
        component: ListComponent
     },
    {
        path: 'add',
        component: AddTourComponent
    },
    {
        path: 'edit',
        component: EditTourComponent
    },
];