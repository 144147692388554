import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../..//api.service';

@Component({
  selector: 'app-agency-account',
  templateUrl: './agency-account.component.html',
  styleUrls: ['./agency-account.component.scss']
})
export class AgencyAccountComponent implements OnInit {
  spinner_mode: ProgressSpinnerMode = 'indeterminate';
  httpWait_fetchingData:boolean=false;
  agency:any;
  account:any;
  transaction_types:any;
  payment_methods:any;
  constructor(
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _api: ApiService,
    private _fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this._route.queryParamMap.subscribe(urlQuery => {
      if(urlQuery['params']['agency_id']){
        this.fetchAgencyAccount(urlQuery['params']['agency_id'])
      }
    });
  }

  fetchAgencyAccount(agency_id){
    this.httpWait_fetchingData = true;
    return this._http.get(`${this._api.base_url}/api/accounting/agency_account/${agency_id}/`).subscribe(
      res => {
        this.agency = res['agency'],
        this.account  = res['agency_account'],
        this.transaction_types = res['transaction_types'],
        this.payment_methods = res['payment_methods'],
        this.httpWait_fetchingData = false;
      },
      err=> {
        this.httpWait_fetchingData = false;
      });
  }

  transactionForm:FormGroup=this._fb.group({
    transaction_type: [null, [Validators.required]],
    payment_amount: [null, [Validators.required]],
    payment_method: [null, [Validators.required]],
    note: [null],
    adjustment_method: [null]
  });

  loadingTransaction:boolean=false;
  addTransaction_active:boolean=false;
  submitTransaction(id) {
    this.loadingTransaction=true;
    let error_container = document.getElementById("error_para")
    error_container.innerHTML = "submitting";
    if(this.transactionForm.valid){
      return this._http.post<any>(this._api.base_url+`/api/accounting/account_transactions/${this.account.id}/`, this.transactionForm.value).subscribe(
        res => {
          this.account.account_transactions.unshift(res['transaction']);
          this.account.account_balance = res['account_balance'];
          error_container.innerHTML = null;
          this.loadingTransaction=false;
          this.transactionForm.reset();
          this.addTransaction_active = false;
        },
        err => {
          error_container.innerHTML = err.message;
          this.loadingTransaction=false;
        }
      );
    } else {
      error_container.innerHTML = "Form is not valid";
      this.loadingTransaction=false;
      return null; // This will handle the case when the form is not valid.
    }
  }
  

}
