import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent, VisaEditDialogue, VisaDeleteDialogue } from './list/list.component';
import { RouterModule } from '@angular/router';
import { VisaRoutes } from './visa.routing';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    ListComponent,
    VisaEditDialogue,
    VisaDeleteDialogue
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(VisaRoutes),
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule
  ],
  entryComponents:[
    VisaEditDialogue,
    VisaDeleteDialogue
  ],
})
export class VisaModule { }
