import { Routes } from '@angular/router';

import { ListComponent } from './list/list.component';
import { AddComponent } from './add/add.component';
import { EditAgencyComponent } from './edit-agency/edit-agency.component';

export const AgencyRoutes: Routes = [
    { path: '',  component: ListComponent },
    { path: 'add', component: AddComponent},
    { path: 'edit', component: EditAgencyComponent}

];
