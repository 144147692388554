import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-tour',
  templateUrl: './add-tour.component.html',
  styleUrls: ['./add-tour.component.scss']
})
export class AddTourComponent implements OnInit {
  apiKey = "qwyj24sqkw361y1o7n0dg6anzn4qk0pd0i47x0oyllpgqo41";
  loading = false;
  submitted = false;
  adultInput;
  fetchingData:boolean=false;
  addTour: FormGroup=this.fb.group({
    vendor: [null, [Validators.required]],
    title: [null],
    hotel: [null, [Validators.required]],
    country: [null, [Validators.required]],
    city: [null, [Validators.required]],
    d_date: [null, [Validators.required]],
    r_date: [null, [Validators.required]],
    quota: [null, [Validators.required]],
    duration: this.fb.group({
      days: [null, [Validators.required]],
      nights: [null, [Validators.required]],
    }),
    pricing: this.fb.array([
    ]),
    note: ["<h3>Day 1</h3><p>Arrive to Istanbul</p>"],
    targetCountry: [null],
    tourType: [null],
    images: this.fb.array([]),
    status: ['unpub', [Validators.required]],
    requires_policy: [false, [Validators.required]],
    policy: [null]
  });
  requiresPolicy:boolean=false;
  cities:any;
  pricing_categories:any;
  policies:any;
  vendors:any;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private router: Router,
    public api: ApiService,
    public calendar: NgbCalendar

    ) {
        this.fromDate = calendar.getToday();
        this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    }

  ngOnInit() {
    this.Agencies();
  }

  get title() {
    return this.addTour.get('title');
  }

  get pricing() {
    return this.addTour.get('pricing') as FormArray;
  }

  get details() {
    return this.addTour.get('details') as FormArray;
  }
  

  addAlternatePrice() {
    if(this.pricing.length < 5){
      const Pricing = this.fb.group({
        type: [null],
        b2b: [null, [Validators.required]],
        r2c: [null, [Validators.required]],      
        b2c: [null, [Validators.required]],   
      })
      this.pricing.push(Pricing);
    }
  }
  addDay(){
    if(this.details.length < 10){
      const details = this.fb.group({
        title: [null],
        content: [null],      
      })
      this.details.push(details);
    }
  }

  removeDay(i){
    this.details.removeAt(i);
  }

  removePrice(i){
    this.pricing.removeAt(i);
  }
  countrySelected(id){
    return this.http.get(this.api.base_url+'/api/misc/city/'+id+'/').subscribe(
      res => {
        this.cities = res
    });
  }
  Agencies(){
    this.fetchingData=true;
    return this.http.get(this.api.base_url+'/api/agency/agencies/').subscribe(
      res => {
        this.vendors = res;
        this.basic_data();
    });
  }

  _url = this.api.base_url+'/api/products/tours/';

  addTourAPI(userData) {
    return this.http.post<any>(this._url, userData);
  }
  base_data:any;
  basic_data(){
    return this.http.get(this.api.base_url+'/api/misc/basic_data/').subscribe(
        res => {
          this.base_data = res,
          this.addPricing(res['p_categories']),
          this.policies = res['policies'],
          this.fetchingData=false;
      });
  }

  addPricing(catgs){
    for(var i in catgs){
        const Pricing = this.fb.group({
          title: catgs[i]['title'],
          type: [catgs[i]['id'], [Validators.required]],
          b2b: [null, [Validators.required]],
          r2c: [null, [Validators.required]],
          b2c: [null, [Validators.required]],   
        })
        this.pricing.push(Pricing);
      }
  }

  onSubmit() {
    this.loading = true;
    console.log(this.addTour.value)
    if (this.addTour.valid) {
        this.addTourAPI(this.addTour.value)
            .subscribe({
                next: (response) => {
                    console.log('Success!', response);
                    this.loading = false;
                    this.router.navigate(['/tours/list']);
                },
                error: (err) => {
                    console.error('Error!', err);
                    this.loading = false;
                }
                // If you had a complete function, you could add it here as well
                // complete: () => { /* logic for completion */ }
            });
    }
}

  get images(){
    return this.addTour.get('images') as FormArray;
  }
  addImage(file, type){
    if(this.images.length < 10){
      const images = this.fb.group({
        title: [null],
        description: [null], 
        img: file,
        img_type: type
      })
      this.images.push(images);
    }
  }
  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
     let reader = new FileReader(); // HTML5 FileReader API
     let file = event[index];
     if (event[index]) {
       reader.readAsDataURL(file);
       // When file uploads set it to file formcontrol
       reader.onload = () => {
        this.addImage(reader.result, file.type)
       }
       // ChangeDetectorRef since file is loading outside the zone
       this.cd.markForCheck();        
     }
    } 
  }
  deleteAttachment(index) {
    this.addTour.get('images').value.splice(index, 1)
  }

  hoveredDate: NgbDate;

  fromDate: NgbDate;
  toDate: NgbDate;

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }
}
