import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

export class Token {
  access?: string;
  refresh?: string;
  username?:any;
  staff?:boolean;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'authExempt': 'true'
  })
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  base_url=this.api.base_url
  private currentUserSubject: BehaviorSubject<Token>;
  public currentUser: Observable<Token>;

  constructor(private http: HttpClient, private router: Router, private api: ApiService ) {
      this.currentUserSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem('AccessToken')));
      this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Token {
      return this.currentUserSubject.value;
  }

  login(username: string, password: string) {

      return this.http.post<any>(this.base_url+'/api/user/login/', { username, password })
          .pipe(map(access => {
              if (access.access && access.refresh && access.staff === true) {
                  // store user details and jwt token in local storage to keep user logged in between page refreshes
                  localStorage.setItem('AccessToken', JSON.stringify(access.access));
                  localStorage.setItem('RefreshToken', JSON.stringify(access.refresh));
                  localStorage.setItem('username', JSON.stringify(access.username));
                  localStorage.setItem('staff', JSON.stringify(access.staff));

                  this.currentUserSubject.next(access);
                  return access;
              }
              else{
                return false
              }
          }));
  }

  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('AccessToken');
      localStorage.removeItem('RefreshToken');
  }
}
