import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../api.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  constructor(
    public http: HttpClient,
    public api: ApiService,
    public fb: FormBuilder,
    public cd: ChangeDetectorRef,
    public router: Router
  ) { }
  agency_types:any;
  ngOnInit() {
    return this.http.get(this.api.base_url+'/api/agency/agency_types/').subscribe(
      res => this.agency_types = res
    )
  }

  agencyform: FormGroup = this.fb.group({
    name  : [null, [Validators.required]],
    email : [null, [Validators.required]],
    country : [null, [Validators.required]],
    city  : [null, [Validators.required]],
    address_hq  : [null, [Validators.required]],
    agency_type : [null, [Validators.required]],
    agency_code: [null, [Validators.required]],
    agency_price_code: [null, [Validators.required]],
    owner : this.fb.group({
      f_name  : [null, [Validators.required]],
      l_name  : [null, [Validators.required]],
      email   : [null, [Validators.required]],
      username  : [null, [Validators.required]],
      password  : [null, [Validators.required]],
      conf_password : [null, [Validators.required]],
      group: [null, [Validators.required]]

    }),
    logo: this.fb.group({
      img: [null, [Validators.required]],
      img_type: [null, [Validators.required]]
    }),
    docs: this.fb.array([]),
    employees: this.fb.array([
    ])
  });
  get employees(){
      return this.agencyform.controls['employees'] as FormArray;
  }

  addEmployee(){
      let employee = this.fb.group({
        f_name  : [null, [Validators.required]],
        l_name  : [null, [Validators.required]],
        email   : [null, [Validators.required]],
        username  : [null, [Validators.required]],
        password  : [null, [Validators.required]],
        conf_password : [null, [Validators.required]],
        group: [null, [Validators.required]]
    });
    this.employees.push(employee);
  }
  error:boolean=false;
  loading: boolean=false;
  addAgency() {
    if (this.agencyform.valid) {
      this.loading = true;
      return this.http.post<any>(this.api.base_url+'/api/agency/agencies/', this.agencyform.value).subscribe(
        res => { 
          this.router.navigate(['/admin/agency']); 
          this.agencyform.reset(); 
        },
        err => { 
          this.error = true; 
        }
      );
    } else {
      return null;  // Return null or some other value when form is not valid
    }
  }
  
  get passwordMatch(): boolean {
    const password = this.agencyform.controls['owner'].get('password').value;
    const conf_password = this.agencyform.controls['owner'].get('conf_password').value;
  
    if (password === null) {
      return false; // Return false or some default value if password is null
    }
  
    return password === conf_password;
  }

  cities:any;
  countrySelected(id){
    return this.http.get(this.api.base_url+'/api/misc/city/'+id+'/').subscribe(
      res => {
        this.cities = res
    });
  }
  uploadlogo(event) {
    for (let index = 0; index < event.length; index++) {
     let reader = new FileReader(); // HTML5 FileReader API
     let file = event[index];
     if (event[index]) {
       reader.readAsDataURL(file);
       // When file uploads set it to file formcontrol
       reader.onload = () => {
        this.agencyform.controls['logo'].patchValue({
          img:reader.result,
          img_type: file.type
        })
       }
       // ChangeDetectorRef since file is loading outside the zone
       this.cd.markForCheck();        
     }
    } 
  }

}