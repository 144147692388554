import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'app/api.service';

@Injectable({
  providedIn: 'root'
})
export class RoadtripService {
  openDestinations: string[];
  indexedDestinations: Destination={};
  fleet_classes: string[];
  fleet_types: string[];
  fleet_vehicles: string[];
  busy_vehicles: string[];
  roadTripSchedule_paginationDisabled:boolean=false;
  roadTripSchedule_fetchingData=false;
  roadTripSchedule_page:number=1;
  roadTripSchedule_searchQuery:string;
  roadTrips_schedule: any= {
    count: null,
    data: null,
    next: null,
    previous: null,
  };
  constructor(
    private _http: HttpClient,
    public _api: ApiService
  ) { }

  get_openDestinations(){
    this._http.get(`${this._api.base_url}/api/products/road_trip/road_trip_destination/`).subscribe(
      res => {
        this.openDestinations = res['data'];
        this.arrange_destinations(res['data']);
      },
      err =>{
      }
    )};

    arrange_destinations(destinations){
      destinations.forEach(destination => {
        this.indexedDestinations[destination.id] = {
          destination_id: destination.id,
          title: destination.city.title,
          route_index: destination.route_index
        };
      });
    }

    get_fleetData(): void {
      this._http.get(`${this._api.base_url}/api/products/road_trip/fleet_control/`).subscribe(
        res => {
          this.fleet_classes = res['data']['fleet_classes'];
          this.fleet_types = res['data']['fleet_types'];
          this.fleet_vehicles = res['data']['fleet_vehicles'];
          this.busy_vehicles = res['data']['busy_vehicles'];
        },
        err => {

        });
    }

    get_roadTripSchedule(page, query): void {
      this.roadTripSchedule_paginationDisabled=true;
      this.roadTripSchedule_fetchingData=true;
      this._http.get(`${this._api.base_url}/api/products/road_trip/land_trip_schedule/?page=${page}&search=${query}`).subscribe(
        res => {
          this.roadTrips_schedule['data'] = res['results'];
          this.roadTrips_schedule['next'] = res['next'];
          this.roadTrips_schedule['previous'] = res['previous'];
          this.roadTrips_schedule['count'] = res['count'];
          if(this.roadTripSchedule_page != page){
            this.roadTripSchedule_page = page;
          }
          this.roadTripSchedule_searchQuery = query;
          this.roadTripSchedule_paginationDisabled=false;
          this.roadTripSchedule_fetchingData=false;

        },
        err => {
          this.roadTripSchedule_paginationDisabled=false;
          this.roadTripSchedule_fetchingData=false;
        }
      )
    }


};

interface Destination {
  [id: string]: {
    destination_id: number,
    title: string,
    route_index: number
  },
}
