import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { tap  } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly JWT_TOKEN = 'AccessToken';
  private readonly REFRESH_TOKEN = 'RefreshToken';

  constructor(private http: HttpClient, private router: Router, private api: ApiService) {}
  base_url=this.api.base_url;
  
  refreshToken(){
    return this.http.post<any>(this.base_url+`/api/user/refresh/`, {
      'refresh': this.getRefreshToken()
    })
    .pipe(
      tap((tokens: Tokens) => {
        this.storeJwtToken(tokens.access);
      })
    );
  }
  handle_Error(err: Response) {
        this.removeTokens();
        window.location.replace("/login");
        return  throwError(err);
  }

  private getRefreshToken(): string {
    const token = localStorage.getItem('RefreshToken');
    if(token){
      return token.replace(/['"]+/g, '');
    }
    throw new Error('No refresh token found'); // Throw an error if no token is found
  }
  

  getJwtToken() {
    if(localStorage.getItem(this.JWT_TOKEN)){
      return localStorage.getItem(this.JWT_TOKEN).replace(/['"]+/g, '');
    }
    else{
      return null
    }
  }
  removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem('username');
    localStorage.removeItem('staff');

  }

   doLogoutUser(error) {
    this.removeTokens();
    window.location.replace("/login");
    return throwError(error); 
  }

  private storeJwtToken(jwt) {
    localStorage.setItem(this.JWT_TOKEN, JSON.stringify(jwt));
  }
  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, JSON.stringify(tokens.access));
    localStorage.setItem(this.REFRESH_TOKEN, JSON.stringify(tokens.refresh));
  }
  isLoggedIn() {
    return !!this.getJwtToken();
  }
}


export class Tokens {
  access: string;
  refresh: string;
}