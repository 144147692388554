import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../..//api.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  spinner_mode: ProgressSpinnerMode = 'indeterminate';
  addTransaction:boolean=false;
  agencies:any;
  accounts:any=[];
  transaction_types:any;
  payment_methods:any;
  transactions:any;
  collectionSize: number;
  httpWait_fetchingTransactionsData:boolean = false;
  page:number=1;
  constructor(
    public _http: HttpClient,
    public _api: ApiService,
    public _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this._route.queryParamMap.subscribe(urlQuery => {
      if(urlQuery['params']['page']){
        this.fetchAccountingData(urlQuery['params'])
      } else{
        this.fetchAccountingData({'page': 1})
      }
    });
    this.Agencies();
    this.AccountsData();
  }

  Agencies(){
    return this._http.get(this._api.base_url+'/api/agency/agencies/').subscribe(
      res => {
        this.agencies = res
    });
  }

  AccountsData(){
    return this._http.get(this._api.base_url+'/api/accounting/accounts/').subscribe(
      res => {
        this.accounts = res['accounts'],
        this.transaction_types = res['transaction_types'],
        this.payment_methods = res['payment_methods']
      },
      err=>{

      }
    )
  }

  fetchAccountingData(params){
    this.httpWait_fetchingTransactionsData = true;
    return this._http.get(`${this._api.base_url}/api/accounting/transactions/`, {params}).subscribe(
      res => {
        this.transactions = res;
        if(this.page != params['page']){
          this.page = Number(params['page']);
        }
        this.transactionFilterForm.patchValue({
          search: params['search'],
          date_start: params['date_start'],
          date_end: params['date_end']
        })
        this.httpWait_fetchingTransactionsData = false;
      },
      err=> {
        this.httpWait_fetchingTransactionsData = false;
      });    
  }
  selected:number=0;
  accountform:FormGroup= this._fb.group({
    account_name: [null, [Validators.required]],
    account_group: ['agency', [Validators.required]],
    account_owner: [null, [Validators.required]],
  });

  account_groups:any[]=[
    {
      id: 1,
      title: 'Individual account',
      code: 'individual'
    },
    {
      id: 2,
      title: 'Company account (Default)',
      code: 'agency'
    }
  ];
  vendors:any;
  users:any;
  creatingAccount:boolean=false;
  loadingAccount:boolean=false;
  create_account() {
    this.creatingAccount=true;
    if(this.accountform.valid){
      this.loadingAccount = true;
      return this._http.post<any>(this._api.base_url+'/api/accounting/accounts/', this.accountform.value).subscribe(
        res => {
          this.creatingAccount=false;
          this.accounts.unshift(res);
          this.loadingAccount=false;
          this.accountform.reset();
        },
        err => {
          this.creatingAccount=false;
          this.loadingAccount=false;
        }
      );
    } else {
      this.creatingAccount=false;
      this.loadingAccount=false;
      return null; // This will handle the case when the form is not valid.
    }
  }
  

  transactionFilterForm: FormGroup = this._fb.group({
    search: [null],
    date_start: [null],
    date_end: [null]
  });
  navigatePage(p){
    let filterParams = {};
    if(this.transactionFilterForm.get('search').value){
      filterParams['search'] = this.transactionFilterForm.get('search').value;
    }
    if(this.transactionFilterForm.get('date_start').value){
      filterParams['date_start'] = this.transactionFilterForm.get('date_start').value;
    }
    if(this.transactionFilterForm.get('date_end').value){
      filterParams['date_end'] = this.transactionFilterForm.get('date_end').value;
    }
    filterParams['page'] = p;
    this._router.navigate(['/accounting'], { queryParams: filterParams});
  }

  transactionFilter(){
    let filterParams = {};
    if(this.transactionFilterForm.get('search').value){
      filterParams['search'] = this.transactionFilterForm.get('search').value;
    }
    if(this.transactionFilterForm.get('date_start').value){
      filterParams['date_start'] = this.transactionFilterForm.get('date_start').value;
    }
    if(this.transactionFilterForm.get('date_end').value){
      filterParams['date_end'] = this.transactionFilterForm.get('date_end').value;
    }
    filterParams['page'] = 1;
    this._router.navigate(['/accounting'], { queryParams: filterParams});
  }

  clearFilter(){
    this.transactionFilterForm.reset();
    this.transactionFilter();
  }
}
