import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent, TourDeleteDialogue } from './list/list.component';
import { RouterModule } from '@angular/router';
import { TourRoutes } from './tours.routing'
import { AddTourComponent } from './add-tour/add-tour.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditTourComponent } from './edit-tour/edit-tour.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DragDropDirective } from 'app/directive/drag-drop.directive';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [
    DragDropDirective,
    ListComponent,
    AddTourComponent,
    EditTourComponent,
    TourDeleteDialogue
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(TourRoutes),
    MatTooltipModule,
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    NgbModule,
    MatCheckboxModule,
    EditorModule
  ],
  entryComponents: [
    TourDeleteDialogue
  ]
})
export class ToursModule { }
