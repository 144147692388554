import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth.service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    category: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Statistics',  icon: 'dashboard', class: '', category:'admin' },
    { path: '/orders', title: 'Orders',  icon:'reorder', class: '', category:'admin' },
    // { path: '/airline', title: 'Air Tickets',  icon:'flight', class: '', category:'admin' },
    { path: '/road-trips', title: 'Road Transportation',  icon:'commute', class: '', category:'admin' },
    { path: '/tours', title: 'Tours',  icon:'beach_access', class: '', category:'admin' },
    // { path: '/hotel', title: 'Hotel',  icon:'hotel', class: '', category:'admin' },
    // { path: '/air', title: 'Air',  icon:'flight', class: '', category:'admin' },
    // { path: '/maps', title: 'Maps',  icon:'location_on', class: '', category:'admin' },
    { path: '/visa', title: 'Visa',  icon:'card_travel', class: '', category:'admin' },
    { path: '/nt-services', title: 'Travel Services',  icon:'emoji_transportation', class: '', category:'admin' },
    { path: '/agency', title: 'Agency Control',  icon:'business', class: '', category:'admin' },
    { path: '/accounting', title: 'Accounting Panel',  icon:'account_balance', class: '', category:'admin' },
    { path: '/policy', title: 'Policy Manager',  icon:'sheet', class: '', category:'admin' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(
    public auth: AuthService
  ) { }
  logout(){
    this.auth.removeTokens();
    window.location.replace("/login");
  }
  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
