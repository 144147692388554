import { Routes } from '@angular/router';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { OrderViewComponent } from './order-view/order-view.component';
import { OrderAddComponent } from './order-add/order-add.component';


export const OrderRoutes: Routes=[
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
    },
    {
        path: 'list',
        component: OrdersListComponent
    },
    {
        path: 'add',
        component: OrderAddComponent
    },
    {
        path: 'order-view/:o_id',
        component: OrderViewComponent
    },

]