import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/api.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { RoadtripService } from '../roadtrip-service.service';
import { DatePipe } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-road-trip-schedule',
  templateUrl: './road-trip-schedule.component.html',
  styleUrls: ['./road-trip-schedule.component.css']
})
export class RoadTripScheduleComponent implements OnInit {
  today:Date= new Date();
  tomorrow:Date = new Date(this.today);
  next_month: Date = new Date(this.today);
  roadTripFormActive: boolean= false;
  formEditState: boolean= false;
  activeEditableTrip_index: number= null;
  httpWaiting_formData: boolean=false;
  constructor(
    private _fb: FormBuilder,
    private _http: HttpClient,
    public api: ApiService,
    public rt_service: RoadtripService,
    private date_pipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute
  ) { }
  roadTripForm: FormGroup= this._fb.group({
    trip_type: [null, [Validators.required]],
    assigned_vehicle: [null, [Validators.required]],
    destination: [null, [Validators.required]],
    starting_station: [null, [Validators.required]],
    status: [null, [Validators.required]],
    d_date: [null, [Validators.required]],
    price_time: this._fb.group({
      est_time: [null, [Validators.required]],
      base_price: [null, [Validators.required]],
      tax_price: [null, [Validators.required]],
      sale_price: [null, [Validators.required]],
      infant_price: [null, [Validators.required]]
    }),
    route_stops: this._fb.array([])
  })
  ngOnInit(): void {
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    this.next_month.setDate(this.next_month.getDate() + 2);

    this.route.queryParamMap.subscribe(urlQuery => {
      let query: string = '';
      if(urlQuery['params']['page'] || urlQuery['params']['search']){
        if(urlQuery['params']['search']){
          query = urlQuery['params']['search'];
        }
        this.rt_service.get_roadTripSchedule(urlQuery['params']['page'], query)
      } else{
        this.rt_service.get_roadTripSchedule(1, '')
      }
    });

  }

  submitNewTrip(){
    // console.log(this.roadTripForm.controls);
    if(this.roadTripForm.valid){
      this.httpWaiting_formData = true;
      this.roadTripForm.get('d_date').setValue(this.date_pipe.transform(this.roadTripForm.get('d_date').value, 'yyyy-MM-ddTHH:mm:00Z'));
      if(!this.formEditState){
        this._http.post(`${this.api.base_url}/api/products/road_trip/road_trip_cr/`, this.roadTripForm.value).subscribe(
          res => {
            this.httpWaiting_formData = false;
            this.rt_service.roadTrips_schedule['data'].push(res['new_trip']);
            this.rt_service.roadTrips_schedule['count']+=1;
            this.resetForm();
            
          },
          err => {
            this.httpWaiting_formData = false;
          });
      }
      else{
        this._http.patch(`${this.api.base_url}/api/products/road_trip/road_trip_edit/${this.rt_service.roadTrips_schedule['data'][this.activeEditableTrip_index]['unique_id']}/`, this.roadTripForm.value).subscribe(
          res => {
            this.httpWaiting_formData = false;
            this.rt_service.roadTrips_schedule['data'][this.activeEditableTrip_index] = res['updated_data'];
            this.resetForm();
          },
          err => {
            this.httpWaiting_formData = false;
          });
      }


    }
  };

  get route_stops(){
    return this.roadTripForm.get('trip_route') as FormArray;
  }


  startingStation_selected(){
    let i=0;
    this.stops.clear();
    this.roadTripForm.get('destination').reset();
  }
  
  destinationSelected(value){
    this.httpWaiting_formData=true;
    this.stops.clear();
    let starting_station_index = this.rt_service.indexedDestinations[this.roadTripForm.get('starting_station').value].route_index;
    let destination_city_index = this.rt_service.indexedDestinations[this.roadTripForm.get('destination').value].route_index;
    let desc:boolean=false;
    if(starting_station_index > destination_city_index){
      desc = true;
    }
    this._http.get(`${this.api.base_url}/api/products/road_trip/road_trip_destination_indexes/?s_index=${starting_station_index}&d_index=${destination_city_index}&desc=${desc}/`)
      .subscribe(
        res=>{
          this.httpWaiting_formData=false,
          res['route_stops'].forEach(route_stop => {
            this.add_destinationStop(route_stop, false);
          });
        },
        err => {
          this.httpWaiting_formData=false;
        }
      );

  };


  add_destinationStop(route_stop, edit){
    let city_title = route_stop.city.title;
    let destination_id = route_stop.id;
    let route_index = route_stop.route_index;
    let is_custom_index:boolean=false;
    if(edit){
      city_title = route_stop.city.city.title
      destination_id = route_stop.city.id;
      route_index = route_stop.custom_index
      is_custom_index = route_stop.is_custom_index;
    }

    let city_stop: FormGroup = this._fb.group({
      destination_id: [destination_id, [Validators.required]],
      city_title: [city_title, [Validators.required]],
      is_custom_index: [is_custom_index, [Validators.required]],
      custom_index: [route_index, [Validators.required]],
      arrival_time: [route_stop.arrival_time],
      stop_duration: [route_stop.stop_duration],
      stop_address: [route_stop.stop_address]
    });
    this.stops.push(city_stop);
  };

  get stops() {
    return this.roadTripForm.get('route_stops') as FormArray;
  }

  routeDrop(event: CdkDragDrop<string[]>) {
    moveItemInFormArray(this.stops, event.previousIndex, event.currentIndex);
  };


  deleteDestinationStop(index) {
    this.stops.controls.splice(index, 1);
  }

  resetForm(){
    this.roadTripForm.reset();
    this.roadTripFormActive=false;
    this.stops.clear();
    if(this.formEditState==true){
      this.formEditState=false;
      this.activeEditableTrip_index = null;
      this.roadTripForm.removeControl('open_seats');
    }
  }

  edit_roadTrip(roadTrip, data_index){
    this.activeEditableTrip_index = data_index;
    let d_date= roadTrip.details.departure_date;
    let departure_date =this.date_pipe.transform(d_date, 'yyyy-MM-ddTHH:mm');

    this.roadTripFormActive = true;
    this.formEditState=true;
    console.log(departure_date);
    this.roadTripForm.patchValue({
      trip_type: roadTrip.details.trip_type,
      assigned_vehicle: roadTrip.details.assigned_vehicle.vehicle_sn,
      destination: roadTrip.details.route.destination_city.id,
      starting_station: roadTrip.details.route.starting_city.id,
      d_date: departure_date,
      status: roadTrip.status.code,
    });
    this.roadTripForm.addControl('open_seats', new FormControl(roadTrip.quota, [Validators.required]));

    this.roadTripForm.get('price_time').patchValue({
      est_time: roadTrip.details.est_time,
      base_price: roadTrip.details.base_price,
      tax_price: roadTrip.details.tax_price,
      sale_price: roadTrip.details.sale_price,
      infant_price: roadTrip.details.infant_price
    });

    roadTrip.details.route.route_stop_cities.forEach(route_stop => {
      this.add_destinationStop(route_stop, true);
    });
  }
  scheduleSearchQuery:string=null;
  navigateSchedulePage(p){
    if(this.scheduleSearchQuery){
      this.router.navigate(
        ['/road-trips/schedule'], {queryParams: { 'page': p, 'search': this.scheduleSearchQuery} }
      );
    }
    else {
      this.router.navigate(
        ['/road-trips/schedule'], {queryParams: { 'page': p} }
      );
    }
  }
}

/**
 * Moves an item in a FormArray to another position.
 * @param formArray FormArray instance in which to move the item.
 * @param fromIndex Starting index of the item.
 * @param toIndex Index to which the item should be moved.
 */

export function moveItemInFormArray(
  formArray: FormArray,
  fromIndex: number,
  toIndex: number
): void {
  const dir = toIndex > fromIndex ? 1 : -1;

  const item = formArray.at(fromIndex);
  for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
    const current = formArray.at(i + dir);
    formArray.setControl(i, current);
  }
  formArray.setControl(toIndex, item);
  for(var _stop in formArray.controls){
    formArray.controls[_stop].patchValue({is_custom_index: true, custom_index: Number(_stop)+1});
  }
}
