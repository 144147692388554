import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../..//api.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-agency',
  templateUrl: './edit-agency.component.html',
  styleUrls: ['./edit-agency.component.scss']
})
export class EditAgencyComponent implements OnInit {

  constructor(
    public http: HttpClient,
    public api: ApiService,
    public fb: FormBuilder,
    public cd: ChangeDetectorRef,
    public router: Router,
    private route: ActivatedRoute
  ) { }
  agency_id:any;
  agency_types:any;
  AgencyTypes(agency_id){
    this.http.get(this.api.base_url+'/api/agency/agency_types/').subscribe(
        res => {
            this.agency_types = res,
            this.AgencyDetails(agency_id)
        }
      )
  }
  ngOnInit() {
    this.route.queryParamMap.subscribe(p => {
        this.agency_id = p['params']['agency_id'];
        this.AgencyTypes(p['params']['agency_id']);
        }
    );
  }
  AgencyDetails(agency_id){
    return this.http.get(this.api.base_url+`/api/agency/agency/${agency_id}/`).subscribe(
        res => {
            this.http.get(this.api.base_url+'/api/misc/city/'+res['country']['cca2']+'/').subscribe(
                res => {
                  this.cities = res
              });
            this.agencyform.patchValue({
                name: res['name'],
                email: res['email'],
                country: res['country']['cca2'],
                city: res['city']['id'],
                address_hq: res['address_hq'],
                agency_type: res['agency_type']['id'],
                agency_code: res['code']            
            });
            if(res['agency_price_code']['code']){ this.agencyform.get('agency_price_code').setValue(res['agency_price_code']['code'])};
            this.agencyform.get('logo').patchValue({
                currentImg: res['logo']
            })
            this.agencyform.get('owner').patchValue({
                f_name: res['owner']['first_name'],
                l_name: res['owner']['last_name'],
                email: res['owner']['email'],
                username: res['owner']['username'],
                group: res['owner']['groups'][0]['id'].toString()
            })
        },
        err => {

        }
    )
  }

  agencyform: FormGroup = this.fb.group({
    name  : [null, [Validators.required]],
    email : [null, [Validators.required]],
    country : [null, [Validators.required]],
    city  : [null, [Validators.required]],
    address_hq  : [null, [Validators.required]],
    agency_type : [null, [Validators.required]],
    agency_code: [null, [Validators.required]],
    agency_price_code: [null, [Validators.required]],
    owner : this.fb.group({
      f_name  : [null, [Validators.required]],
      l_name  : [null, [Validators.required]],
      email   : [null, [Validators.required]],
      username  : [null, [Validators.required]],
      password  : [null],
      conf_password : [null],
      group: [null, [Validators.required]],
      change_password: [null]
    }),
    logo: this.fb.group({
      currentImg: [null, Validators.required],
      img: [null],
      img_type: [null]
    }),
  });

  error:boolean=false;
  error_message:any="";
  loading: boolean=false;
  updateAgency(){
    console.log(this.agencyform.value)
    if (this.agencyform.valid){
      this.loading = true
      return this.http.patch<any>(this.api.base_url+`/api/agency/agency/${this.agency_id}/`, this.agencyform.value).subscribe(
        res => {this.router.navigate(['/agency']), this.agencyform.reset()},
        err => {this.error = true, this.loading=false, this.error_message=err.error}
      );
    }
    return null;
  }
  get passwordMatch(){
    let password = this.agencyform.controls['owner'].get('password').value
    let conf_password = this.agencyform.controls['owner'].get('conf_password').value
    if (password !=null){
      if (password == conf_password){
        return true
      }
      else{
        return false
      }
    }
    return false;
  }
  cities:any;
  countrySelected(id){
    return this.http.get(this.api.base_url+'/api/misc/city/'+id+'/').subscribe(
      res => {
        this.cities = res
    });
  }
  uploadlogo(event) {
    for (let index = 0; index < event.length; index++) {
     let reader = new FileReader(); // HTML5 FileReader API
     let file = event[index];
     if (event[index]) {
       reader.readAsDataURL(file);
       // When file uploads set it to file formcontrol
       reader.onload = () => {
        this.agencyform.controls['logo'].patchValue({
          img:reader.result,
          img_type: file.type
        })
       }
       // ChangeDetectorRef since file is loading outside the zone
       this.cd.markForCheck();        
     }
    } 
  }

}
