import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgencyModule } from './agency/agency.module';
import { AgencyComponent } from './agency/agency.component';
import { VisaModule } from './visa/visa.module';
import { VisaComponent } from './visa/visa.component';
import { AccountingModule } from './accounting/accounting.module';
import { AccountingComponent } from './accounting/accounting.component';
import { ToursModule } from './tours/tours.module';
import { ToursComponent } from './tours/tours.component';
import { OrdersModule } from './orders/orders.module';
import { OrdersComponent } from './orders/orders.component';
import { AuthGuard } from './auth-guard.service';
import { AuthService } from './auth.service';
import { LoginComponent } from './login/login.component';
import { TokenInterceptor } from './interceptor';
import { ApiService } from './api.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AirlineComponent } from './airline/airline.component';
import { NtServicesComponent } from './nt-services/nt-services.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RoadtripComponent } from './roadtrip/roadtrip.component';
import { RoadtripModule } from './roadtrip/roadtrip.module';
import { RoadtripService } from './roadtrip/roadtrip-service.service';
import { DatePipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { EditorModule } from '@tinymce/tinymce-angular';
import { PolicyComponent, PolicyDialog } from './policy/policy.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    AgencyModule,
    AccountingModule,
    VisaModule,
    AccountingModule,
    ToursModule,
    OrdersModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatTooltipModule,
    MatDialogModule,
    RoadtripModule,
    EditorModule
    

  ],
  declarations: [
    AppComponent,
    AgencyComponent,
    VisaComponent,
    AccountingComponent,
    ToursComponent,
    OrdersComponent,
    LoginComponent,
    DashboardComponent,
    AirlineComponent,
    NtServicesComponent,
    RoadtripComponent,
    PolicyComponent,
    PolicyDialog
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthGuard,
    AuthService,
    ApiService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
