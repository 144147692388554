import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/api.service';
import { RoadtripService } from '../roadtrip-service.service';

@Component({
  selector: 'app-road-trip-fleet',
  templateUrl: './road-trip-fleet.component.html',
  styleUrls: ['./road-trip-fleet.component.css']
})
export class RoadTripFleetComponent implements OnInit {
  // today:Date= new Date();
  // tomorrow:Date = new Date(this.today);
  // next_month: Date = new Date(this.today);
  fleetFormActive: boolean= false;
  httpWaiting_formData: boolean=false;
  constructor(
    private _http: HttpClient,
    private _fb: FormBuilder,
    private _api: ApiService,
    private cd: ChangeDetectorRef,
    public rt_service: RoadtripService
  ) { }
  fleetFormGroup: FormGroup= this._fb.group({
    fleet_name: [null, [Validators.required]],
    fleet_no: [null, [Validators.required]],
    fleet_type: [null, [Validators.required]],
    max_pax: [null, [Validators.required]],
    fleet_class: [null, [Validators.required]],
    fleet_images: this._fb.array([]),
    driver_name: [null, [Validators.required]],
    driver_phone: [null, [Validators.required]],
    fleet_thumbnail: this._fb.group({
      img_src: [null, [Validators.required]],
      img_type: [null, [Validators.required]]
    })
  });

  ngOnInit(): void {
  }

  resetForm(){
    this.fleetFormGroup.reset();
    this.fleetFormActive=false;
  }

  get fleet_images(){
    return this.fleetFormGroup.get('fleet_images') as FormArray;
  }

  thumbnail_added:boolean=false;
  addImage(file, type){
    if((this.fleet_images.length < 10) && this.thumbnail_added){
      const image = this._fb.group({
        title: [null],
        description: [null], 
        img_src: file,
        img_type: type
      })
      this.fleet_images.push(image);
    }

    if(!this.thumbnail_added){
      this.fleetFormGroup.get('fleet_thumbnail').patchValue({
        img_src: file,
        img_type: type
      });
      this.thumbnail_added=true;
    }
    console.log(this.fleet_images.value)
  }

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
     let reader = new FileReader(); // HTML5 FileReader API
     let file = event[index];
     if (event[index]) {
       reader.readAsDataURL(file);
       // When file uploads set it to file formcontrol
       reader.onload = () => {
        this.addImage(reader.result, file.type)
       }
       // ChangeDetectorRef since file is loading outside the zone
       this.cd.markForCheck();        
     }
    } 
  }
  deleteAttachment(index, is_thumbnail) {
    if(is_thumbnail){
      this.fleetFormGroup.get('fleet_thumbnail').reset();
      this.thumbnail_added=false;
    }
    else{
      this.fleetFormGroup.get('fleet_images').value.splice(index, 1)
    }
  }




  submitNewFleet(){
    this.httpWaiting_formData = true;
    if(this.fleetFormGroup.valid){
      this._http.post(`${this._api.base_url}/api/products/road_trip/fleet_control/`, this.fleetFormGroup.value).subscribe(
        res => {
          this.rt_service.fleet_vehicles.push(res['new_vehicle']);
          this.httpWaiting_formData = false;
          this.resetForm();
        },
        err => {
          this.httpWaiting_formData = false;
        });
    }};
}
