import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { RouterModule } from '@angular/router';
import { AgencyRoutes } from './agency.routing';
import { AddComponent } from './add/add.component';
import { DragDropDirective } from 'app/directive/drag-drop.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EditAgencyComponent } from './edit-agency/edit-agency.component';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [ListComponent, AddComponent, EditAgencyComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(AgencyRoutes),
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    NgbModule,
    MatProgressSpinnerModule
  ],
  providers: [
    DragDropDirective
  ]
})
export class AgencyModule { }
