import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visa',
  templateUrl: './visa.component.html',
  styleUrls: ['./visa.component.scss']
})
export class VisaComponent implements OnInit {



  constructor() {}

  ngOnInit() {
  }
  ngAfterViewInit() {
  }

}
