import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountsComponent } from './accounts/accounts.component';
import { RouterModule } from '@angular/router';
import { AccountingRoutes } from './accounting.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { AgencyAccountComponent } from './agency-account/agency-account.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { NgbDropdownModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AccountsComponent, AgencyAccountComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(AccountingRoutes),
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatInputModule,
    NgbPaginationModule,
    NgbDropdownModule,
  ]
})
export class AccountingModule { }
