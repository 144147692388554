import { Routes } from '@angular/router';
import { RoadTripFleetComponent } from './road-trip-fleet/road-trip-fleet.component';
import { RoadTripRoutesComponent } from './road-trip-routes/road-trip-routes.component';
import { RoadTripScheduleComponent } from './road-trip-schedule/road-trip-schedule.component';

import { RoadtripComponent } from './roadtrip.component';

export const RoadTripRoutes: Routes = [
    {
        path: '',
        redirectTo: 'schedule',
        pathMatch: 'full',
    },
    {
        path: '',  
        component: RoadtripComponent,
        children: [
            { path: 'schedule', component: RoadTripScheduleComponent},
            { path: 'fleet', component: RoadTripFleetComponent},
            { path: 'routes', component: RoadTripRoutesComponent}
        ]
    },


];
