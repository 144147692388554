import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit {
  base_url:string=this.api.base_url;
  refundConfirmation:boolean;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    public api: ApiService,
) {}
  u_id:string;
  statuses:any[];
  outcomes:any[];
  getStatcodes(service_type){
    return this.http.get(this.api.base_url+`/api/booking/status_codes/?st=${service_type}`).subscribe(
      res=>{
        this.statuses=res['status'],
        this.outcomes=res['outcome']
      }
    )
  }
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.u_id = params.get("o_id")
      this.http.get(this.api.base_url+'/api/booking/book/'+params.get("o_id")+'/').subscribe(
        res => {
          this.order=res,
          this.processForm.patchValue({
            viewed: res['process']['viewed'],
            verified: res['process']['verified'],
            vendor_notify: res['process']['vendor_notify'],
            seats_booked: res['process']['seats_booked'],
            customer_notify: res['process']['customer_notify'],
            completed: res['process']['completed']
          });
          this.getStatcodes(res['product']['service_type']['code'])

          this.processReady = true;
          this.statusForm.patchValue({
            status: res['status']['code'],
            outcome: res['outcome']['code']
          })
        }
      );
    });
  }
  processReady:boolean = false;
  order:any;
  order_accounting:any=[]

  openImage(n, i): void {
    const dialogRef = this.dialog.open(Imgdialog, {
      height: '500px',
      width: 'auto',
      data: {name: n, img: i, url: this.base_url}
    });

    // dialogRef.afterClosed().subscribe(result => {
    // });
  }
  processForm:FormGroup= this.fb.group({
    viewed: [false, [Validators.required]],
    verified: [false, [Validators.required]],
    vendor_notify: [false, [Validators.required]],
    seats_booked: [false, [Validators.required]],
    customer_notify: [false, [Validators.required]],
    completed: [false, [Validators.required]]
  });

  updateValue(c, value){
    this.processForm.get(c).patchValue = value;
  };
  updating:boolean = false;
  updatedProcess(){
    this.updating = true;
    if(this.processForm.valid){
      return this.http.patch<any>(this.api.base_url+`/api/booking/booking_process_updated/${this.u_id}/`, this.processForm.value).subscribe(
        res => {
          this.updating = false;
        },
        err=> {
          this.updating = false;
        }
      );
    }
    return null;

  }

  statusForm:FormGroup=this.fb.group({
    status: [null, [Validators.required]],
    outcome: [null, [Validators.required]]
  })
  updatingStatus:boolean;
  updateStatus(){
    this.updatingStatus = true
    if(this.statusForm.valid){
      return this.http.patch<any>(this.api.base_url+`/api/booking/status_update/${this.u_id}/`, this.statusForm.value).subscribe(
        res => {
          this.updatingStatus = false

        },
        err => {
          this.updatingStatus = false

        }
      );
    }
    return null;

  }


  updatingRefNo:boolean=false;
  updateRefNo(ref_no, index, aid){
    this.updatingRefNo = true
    if(this.statusForm.valid){
      return this.http.patch<any>(this.api.base_url+`/api/booking/applicant_ref_no/${this.u_id}/`, {'ref_no': ref_no, 'applicant_id': aid}).subscribe(
        res => {
          this.updatingRefNo = false
          this.order.applicants[index]['ref_no'] = res['ref_no'];
        },
        err => {
          this.updatingRefNo = false

        }
      );
    }
    return null;

  }

  httpWait_submittingRefund:boolean=false;
  refundFormActive: boolean=false;
  refundForm:FormGroup=this.fb.group({
    refund_amount: [null, [Validators.required]],
    note: [null],
    pnr_serial: [null, [Validators.required]]
  });

  submitRefund(){
    if(this.refundConfirmation && this.refundForm.valid){
      this.httpWait_submittingRefund = true;
      return this.http.post(`${this.api.base_url}/api/booking/order_refund/${this.order.unique_id}/`, this.refundForm.value).subscribe(
        res => {
          this.order['booking_transaction_purchase'].unshift(res['transaction']);
          this.httpWait_submittingRefund = false;
          this.resetRefundForm();
          this.refundConfirmation = false;
        },
        err => {
          this.httpWait_submittingRefund = false;
          this.resetRefundForm();
        }
      )
    }
    return null;

  }

  startRefund(payment_amount, order_pnr, payment_pnr){
    this.refundForm.patchValue({
      refund_amount: 0-payment_amount,
      pnr_serial: order_pnr+'-'+payment_pnr
    })
    this.refundFormActive=true;
  }

  resetRefundForm(){
    this.refundFormActive=false;
    this.refundForm.reset();
  }
}

@Component({
  selector: 'img-dialog',
  templateUrl: 'img-dialog.html',
})
export class Imgdialog {

  constructor(
    public dialogRef: MatDialogRef<Imgdialog>,
    @Inject(MAT_DIALOG_DATA) public data: imgData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
export interface imgData {
  name: string;
  img: string;
  url: string;
}