import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RoadTripRoutes } from './roadtrip.routing';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RoadTripRoutesComponent } from './road-trip-routes/road-trip-routes.component';
import { RoadTripFleetComponent } from './road-trip-fleet/road-trip-fleet.component';
import { RoadTripScheduleComponent } from './road-trip-schedule/road-trip-schedule.component';
import { DragDropModule } from '@angular/cdk/drag-drop';


@NgModule({
  declarations: [
    RoadTripRoutesComponent, 
    RoadTripFleetComponent,
    RoadTripScheduleComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(RoadTripRoutes),
    MatTooltipModule,
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    NgbModule,
    MatCheckboxModule,
    DragDropModule
  ]
})
export class RoadtripModule { }
