import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoadtripService } from '../roadtrip-service.service';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-road-trip-routes',
  templateUrl: './road-trip-routes.component.html',
  styleUrls: ['./road-trip-routes.component.css']
})
export class RoadTripRoutesComponent implements OnInit {
  today:Date= new Date();
  addNewDestination:boolean=false;
  cities;
  httpWait_newDestinationForm:boolean=false;
  constructor(
    private _fb: FormBuilder,
    private _http: HttpClient,
    public api: ApiService,
    public rt_service: RoadtripService
  ) { }

  newDestinationForm: FormGroup = this._fb.group({
    country: [null, [Validators.required]],
    destination: [null, [Validators.required]],
    route_index: [null, [Validators.required]],
    note: [null, [Validators.required]]
  })

  ngOnInit(): void {
  }

  reset_newDestinationForm(){
    this.newDestinationForm.reset();
    this.addNewDestination=false;
  }

  submitNewDestination(){
    if(this.newDestinationForm.valid){
      this.httpWait_newDestinationForm = true;
      return this._http.post(`${this.api.base_url}/api/products/road_trip/road_trip_destination/`, this.newDestinationForm.value).subscribe(
        res => {
          this.httpWait_newDestinationForm = false;
          this.reset_newDestinationForm();
          this.rt_service.openDestinations.push(res['new_destination'])
        },
        err => {
          this.httpWait_newDestinationForm = false;

        }
      )
    }
    return null;
  }


  countrySelected(id){
    return this._http.get(this.api.base_url+'/api/misc/city/'+id+'/').subscribe(
      res => {
        this.cities = res;
    });
  }

  
}
