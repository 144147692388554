import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api.service';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-tour',
  templateUrl: './edit-tour.component.html',
  styleUrls: ['./edit-tour.component.scss']
})
export class EditTourComponent implements OnInit {
  apiKey = "qwyj24sqkw361y1o7n0dg6anzn4qk0pd0i47x0oyllpgqo41";
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    public api: ApiService,
    public calendar: NgbCalendar

    ) {
        this.fromDate = calendar.getToday();
        this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    }
  loading = false;
  submitted = false;
  fetchingData:boolean=false;
  adultInput;
  editTour: FormGroup=this.fb.group({
    vendor: [null, [Validators.required]],
    title: [null],
    hotel: [null, [Validators.required]],
    country: [null, [Validators.required]],
    city: [null, [Validators.required]],
    d_date: [null, [Validators.required]],
    r_date: [null, [Validators.required]],
    quota: [null, [Validators.required]],
    duration: this.fb.group({
      days: [null, [Validators.required]],
      nights: [null, [Validators.required]],
    }),
    pricing: this.fb.array([
    ]),
    note: [null],
    targetCountry: [null],
    tourType: [null],
    curImages: this.fb.array([]),
    images: this.fb.array([]),
    status: ['unpub', [Validators.required]],
    policy: [null],
    requires_policy: [null, [Validators.required]]
  });

  cities:any;
  pricing_categories:any;
  vendors:any;
  loadingDetails:boolean=true;
  productImages:any;
  ngOnInit() {
    this.Agencies();
  }

  get title() {
    return this.editTour.get('title');
  }

  get pricing() {
    return this.editTour.get('pricing') as FormArray;
  }

  get details() {
    return this.editTour.get('details') as FormArray;
  }
  

  addExistingPrices(pricing) {
      this.pricing.controls.forEach( (arr) => {
        if(pricing['category']['id'] == arr.get('label').value){
            arr.get(pricing['variety']['code'].toLowerCase()).patchValue(pricing['price'])
        }
      });
  }

  addPricing(catgs){
    for(var i in catgs){
      const Pricing = this.fb.group({
          label : catgs[i]['id'],
          title: catgs[i]['title'],
          type: [catgs[i]['id'], [Validators.required]],
          b2b: [null, [Validators.required]],
          r2c: [null, [Validators.required]],      
          b2c: [null, [Validators.required]],   
      })
      
      this.pricing.push(Pricing);
    }
  }

  addDay(){
    if(this.details.length < 10){
      const details = this.fb.group({
        title: [null],
        content: [null],      
      })
      this.details.push(details);
    }
  }

  removeDay(i){
    this.details.removeAt(i);
  }

  removePrice(i){
    this.pricing.removeAt(i);
  }
  countrySelected(id){
    return this.http.get(this.api.base_url+'/api/misc/city/'+id+'/').subscribe(
      res => {
        this.cities = res
    });
  }
  Agencies(){
    this.fetchingData=true;
    return this.http.get(this.api.base_url+'/api/agency/agencies/').subscribe(
      res => {
        this.vendors = res;
        this.basic_data();
    });
  }
  requiresPolicy:boolean=false;
  policies:any;
  _url:string;

  base_data:any;

  basic_data(){
    return this.http.get(this.api.base_url+'/api/misc/basic_data/').subscribe(
        res => {
            this.base_data = res;
            this.policies = res['policies']
            this.addPricing(res['p_categories']);
            let d_date;
            let r_date;
            this.route.queryParamMap.subscribe(p => {
            this._url = `${this.api.base_url}/api/products/tour/${p['params']['p']}/`;
            this.loadingDetails = true;
            return this.http.get(this.api.base_url+'/api/products/tour/'+p['params']['p']+'/').subscribe(
                res => {
                this.loadingDetails = false,
                this.editTour.patchValue({
                    vendor: res['vendor']['id'],
                    title: res['details']['title'],
                    hotel: res['details']['hotel'],
                    country: res['details']['country']['cca2'],
                    city: res['details']['city']['id'],
                    d_date : res['details']['d_date'],
                    r_date: res['details']['r_date'],
                    quota: res['quota'],
                    note: res['details']['note'],
                    status: res['status']['code'],
                    requires_policy: res['requires_policy']
                })

                if(res['requires_policy'] && res['policy']){
                  this.editTour.patchValue({
                    policy: res['policy']['uid'],
                  })
                }
                
                this.requiresPolicy = res['requires_policy']
                this.editTour.get('duration').patchValue({
                    days: res['details']['days'],
                    nights: res['details']['nights'],
                });
                let curImages = this.editTour.get('curImages') as FormArray;
                if(res['details']['activity']){
                    res['details']['activity'].forEach(activity => {
                        this.details.push(this.fb.group({
                            title: activity['title'],
                            content: activity['content'],      
                        }));
                    });
                }
                this.productImages = res['details']['images']
                res['details']['images'].forEach((image, i) =>{
                    curImages.push(
                        this.fb.group({
                            id: image['id'],
                            index: i,
                            link: image['link'],
                            delete: false,
                        })
                    )
                });
                this.fetchingData=false;
                return this.http.get(this.api.base_url+'/api/misc/city/'+res['details']['country']['cca2']+'/').subscribe(
                    res => {
                    this.cities = res
                }),
                
                res['details']['pricing'].forEach((p) => {
                    this.addExistingPrices(p);
                        });
                    }
                )
            });
      });
  }

  editTourAPI(userData) {
    return this.http.patch<any>(this._url, userData);
  }

  onSubmit() {
    this.loading = true;
    if (this.editTour.valid) {
        this.editTourAPI(this.editTour.value)
            .subscribe({
                next: (response) => {
                    this.loading = false;
                    this.router.navigate(['/tours/list']);
                },
                error: (err) => {
                    this.loading = false;
                    // You might want to handle or log the error here
                    console.error(err);
                }
                // If you had a complete function, you could add it here as well
                // complete: () => { /* logic for completion */ }
            });
    }
}

  get images(){
    return this.editTour.get('images') as FormArray;
  }
  addImage(file, type){
    if(this.images.length < 10){
      const images = this.fb.group({
        title: [null],
        description: [null], 
        img: file,
        img_type: type
      })
      this.images.push(images);
    }
  }
  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
     let reader = new FileReader(); // HTML5 FileReader API
     let file = event[index];
     if (event[index]) {
       reader.readAsDataURL(file);
       // When file uploads set it to file formcontrol
       reader.onload = () => {
        this.addImage(reader.result, file.type)
       }
       // ChangeDetectorRef since file is loading outside the zone
       this.cd.markForCheck();        
     }
    } 
  }
  deleteAttachment(index) {
    this.editTour.get('images').value.splice(index, 1)
  }

  hoveredDate: NgbDate;

  fromDate: NgbDate;
  toDate: NgbDate;

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

}