import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';  // updated import

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss']
})
export class AgencyComponent implements OnInit {

    constructor() {}

    ngOnInit() {
    }
    
    ngAfterViewInit() {
    }
}
