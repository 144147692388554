import { Component, OnInit } from '@angular/core';
import * as Chartist from 'chartist';
import { ApiService } from 'app/api.service';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  base_url = this.api.base_url;
  loading:boolean=false;
  stats:any;
  monthly_stats:string[];
  constructor(
    private http: HttpClient,
    public api: ApiService,
    ) {}

  startAnimationForLineChart(chart){
      let seq: any, delays: any, durations: any;
      seq = 0;
      delays = 80;
      durations = 500;

      chart.on('draw', function(data) {
        if(data.type === 'line' || data.type === 'area') {
          data.element.animate({
            d: {
              begin: 600,
              dur: 700,
              from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint
            }
          });
        } else if(data.type === 'point') {
              seq++;
              data.element.animate({
                opacity: {
                  begin: seq * delays,
                  dur: durations,
                  from: 0,
                  to: 1,
                  easing: 'ease'
                }
              });
          }
      });

      seq = 0;
  };
  startAnimationForBarChart(chart){
      let seq2: any, delays2: any, durations2: any;

      seq2 = 0;
      delays2 = 80;
      durations2 = 500;
      chart.on('draw', function(data) {
        if(data.type === 'bar'){
            seq2++;
            data.element.animate({
              opacity: {
                begin: seq2 * delays2,
                dur: durations2,
                from: 0,
                to: 1,
                easing: 'ease'
              }
            });
        }
      });

      seq2 = 0;
  };
  ngOnInit() {
    this.loading=true;
    this.http.get(this.base_url+'/api/analysis/stats/').subscribe(
      res => {
        this.stats=res,
        this.monthlyBookingStatsChart(res['monthly_orders']),
        this.dailyBookingSales(res['weekly_report']),
        this.loading=false
      }
    )

      /* ----------==========     Completed Tasks Chart initialization    ==========---------- */

      const dataCompletedTasksChart: any = {
          labels: ['12p', '3p', '6p', '9p', '12p', '3a', '6a', '9a'],
          series: [
              [230, 750, 450, 300, 280, 240, 200, 190]
          ]
      };

     const optionsCompletedTasksChart: any = {
          lineSmooth: Chartist.Interpolation.cardinal({
              tension: 0
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: { top: 0, right: 0, bottom: 0, left: 0}
      }

      var completedTasksChart = new Chartist.Line('#completedTasksChart', dataCompletedTasksChart, optionsCompletedTasksChart);

      // start animation for the Completed Tasks Chart - Line Chart
      this.startAnimationForLineChart(completedTasksChart);

  }


  monthlyBookingStatsChart(data){
    var bookingData = {
      // labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      labels: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],

      series: [
          data['Jan'][0],
          data['Feb'][0], 
          data['Mar'][0], 
          data['Apr'][0], 
          data['May'][0], 
          data['Jun'][0], 
          data['Jul'][0], 
          data['Aug'][0], 
          data['Sep'][0], 
          data['Oct'][0], 
          data['Nov'][0], 
          data['Dec'][0],
      ],
      hyperlink:[
        data['Jan'][1],
        data['Feb'][1], 
        data['Mar'][1], 
        data['Apr'][1], 
        data['May'][1], 
        data['Jun'][1], 
        data['Jul'][1], 
        data['Aug'][1], 
        data['Sep'][1], 
        data['Oct'][1], 
        data['Nov'][1], 
        data['Dec'][1]
      ]
    };
    var optionsBookingStatsChart = {
        axisX: {
            showGrid: true,
            // type: Chartist.FixedScaleAxis,
            // ticks: bookingData.labels.map((v, i) => i),
            labelOffset: {
              x: 28
            },
        },

        low: 0,
        seriesBarDistance: 2,
        high: 250,
        distributeSeries: true,           
        chartPadding: { top: 0, right: 5, bottom: 0, left: 0},
        events: ['click', 'hover'],

      };


    var responsiveOptions: any[] = [
      ['screen and (max-width: 640px)', {
        axisX: {
          showGrid: true,
          // type: Chartist.FixedScaleAxis,
          // ticks: bookingData.labels.map((v, i) => i),
          low: -1,
          high: 150,
          seriesBarDistance: 20,
          labelOffset: {
            x: 0
          }
      },
      }]
    ];
    var bookingStatsChart = new Chartist.Bar('#BookingStatsChart',
    bookingData,
    optionsBookingStatsChart,
    responsiveOptions,
    );

    this.startAnimationForbookingStatsChart(bookingStatsChart);
  }

  startAnimationForbookingStatsChart(chart){
    let seq2: any, delays2: any, durations2: any;
    seq2 = 0;
    delays2 = 80;
    durations2 = 500;
    chart.on('draw', function(data) {
      if(data.type === 'bar'){
          seq2++;
          data.element.attr({
            'url-filter': chart.data.hyperlink[data.index],
            'ct:value': data.series[data.index],
            'style': 'stroke: rgb(255 255 255 / 50%); transition: 400ms ease; stroke-width: 10px;'
          });

          data.element.animate({
            opacity: {
              begin: seq2 * delays2,
              dur: durations2,
              from: 0,
              to: 1,
              easing: 'ease'
            }
          });
      }
    });

  
    // attach an event handler to the "created" event of the chart:
    chart.on("created", function () {
      // attach the necessary events to the nodes:
      $('.ct-chart-bar .ct-bar').mouseover(function () {
          // var url = $(this).attr("url-filter");
          $(this).css('stroke', 'rgb(255 255 255 / 100%)');
      });
      $('.ct-chart-bar .ct-bar').mouseleave(function () {
        // var val = $(this).attr("ct:value");
        // var url = $(this).attr("url-filter");
        $(this).css('stroke', 'rgb(236 236 236 / 50%)');

      });
    });

    seq2 = 0;
  };

  dailyBookingSales(weekData){
      /* ----------==========     Daily Sales Chart initialization    ==========---------- */

      const dataDailySalesChart: any = {
        labels: [],
        series: [
            []
        ]
    };
    for(var weekDay in weekData){
      dataDailySalesChart.labels[weekDay]= weekData[weekDay]['day']
      dataDailySalesChart.series[0][weekDay]= weekData[weekDay]['orders']
    }
    
   const optionsDailySalesChart: any = {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
        }),
        axisX: {
          labelOffset: {
            x: -12
          }
        },
        distributeSeries: true,
        low: 0,
        high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: { top: 0, right: 0, bottom: 0, left: 0},
    }

    var dailySalesChart = new Chartist.Line('#dailySalesChart', dataDailySalesChart, optionsDailySalesChart);

    this.startAnimationForLineChart(dailySalesChart);
  }
}