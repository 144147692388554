import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private http: HttpClient, private api: ApiService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const refreshToken = localStorage.getItem('RefreshToken');
      const accessToken = localStorage.getItem('AccessToken');
      const staff:boolean = Boolean(localStorage.getItem('staff'));

        if (refreshToken && accessToken && staff === true) {
            return true;
        }
        else{
            window.location.replace('/login');
            return false;
        }
        // not logged in so redirect to login page with the return url
    }
}